*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto";
}

.App {
  text-align: center;
  color: #000000;
  font-size: 90%;
}
